import styled from 'styled-components'

/* Component styling */

const Input = styled.input`
  padding: 10px 0px;
  width: 100%;
  color: black;
  font-size: 16px;
  background: white;
  border-bottom: 2px solid #cbcbcb;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
  margin: auto;
  text-align: center;

  :focus {
    outline: 0;
  }

  ::placeholder {
    color: #cbcbcb;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.5em;
  }
`

/* Component & Export */

export default Input

import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components'
import SignupForm from './form'
import xButtonImage from './xbutton.png'
import { fadeIn } from 'react-animations'

/* Component */

const SignupModal = ({ onClose }) => {
  useEffect(() => {
    document.body.style['overflow-y'] = 'hidden'
    return () => {
      document.body.style['overflow-y'] = 'auto'
    }
  })

  return createPortal(
    <SignupContainer>
      <Xbutton onClick={onClose}>
        <Image src={xButtonImage} />
      </Xbutton>
      <Background tab-index="-1" />
      <SignupForm />
    </SignupContainer>,
    document.getElementById('portal')
  )
}

/* Component Export */

export default SignupModal

/* Component styling */

const fadeInAnimation = keyframes`${fadeIn}`

const SignupContainer = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  animation: 0.4s ${fadeInAnimation};
`

const Background = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.95);
`

const Xbutton = styled.div`
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 2;
  cursor: pointer;
`

const Image = styled.img`
  filter: invert(40%);
`

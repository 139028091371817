import React from 'react'
import styled, { css, keyframes } from 'styled-components'

/* Component */

const Button = ({ onClick, children, isLoading, disabled, className }) => (
  <StyledButton
    className={className}
    type="submit"
    disabled={disabled}
    onClick={onClick}
  >
    {isLoading && <Loader />}
    {children}
  </StyledButton>
)

/* Component Export */

export default Button

/* Component Styling */

const StyledButton = styled.button`
  background: #5879ed;
  border: hidden;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  font-weight: 200;
  outline: 0px;
  padding: 3px 10px;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  :hover {
    box-shadow: 2px 4px 5px grey;
    font-weight: 400;
  }

  @media screen and (min-width: 568px) {
    font-size: calc(12px + (16 - 12) * ((100vw - 568px) / (1200 - 568)));
    padding: 1.5% 7%;
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
      box-shadow: none;

      :hover {
        box-shadow: none;
        font-weight: 450;
      }
    `}
`

const loaderRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
`

const Loader = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  position: absolute;
  right: 20px;
  top: 8px;

  @media screen and (min-width: 568px) {
    right: 30px;
    top: 15px;
  }

  :after {
    content: ' ';
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${loaderRotate} 1.2s linear infinite;
  }
`

import React, { useState } from 'react'
import styled from 'styled-components'
import LogoImage from './logo-image'
import FormSignUp from '../sign-up'
import Button from '../sign-up/button'

/* Component text */

const text = {
  headline: 'SPADE',
  button: 'Get Early Access!',
}

/* Component */

const Header = () => {
  const [open, setOpen] = useState(false)
  return (
    <HeaderContainer>
      <ImageContainer>
        <LogoImage />
      </ImageContainer>
      <Headline>{text.headline}</Headline>
      <ButtonContainer>
        <Button type="button" onClick={() => setOpen(true)}>
          {text.button}
        </Button>
      </ButtonContainer>
      {open && <FormSignUp onClose={() => setOpen(false)} />}
    </HeaderContainer>
  )
}

/* Component export */

export default Header

/* Component styling */

const HeaderContainer = styled.div`
  display: flex;
  margin: 10px 20px;

  @media screen and (min-width: 568px) {
    max-width: 1200px;
    margin: 0px auto;
    padding-top: 10px;
  }
`

const ImageContainer = styled.div`
  width: 25px;

  @media screen and (min-width: 568px) {
    margin-left: 45px;
    width: 42px;
  }
`

const Headline = styled.h6`
  margin-top: 15px;
  margin-left: 5px;

  @media screen and (min-width: 568px) {
    font-size: 21px;
    margin-top: 30px;
    margin-left: 10px;
  }
`

const ButtonContainer = styled.div`
  white-space: nowrap;
  align-self: center;
  margin-left: auto;

  @media screen and (min-width: 568px) {
    display: none;
  }
`

import React, { useState } from 'react'
import Input from './input'
import Button from './button'
import styled from 'styled-components'
import axios from 'axios'

/* Component text */

const text =
  "We're launching soon, sign up for SPADE & take your fintech application to the next level."
const successText =
  "Thank you so much for signing up to SPADE. We appreciate your excitement & interest. We can't wait to bring you the best fintech tools to support your business."

/* helper validation function */

const emailValidation = email =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

/* Component */

const SignupForm = () => {
  const [email, setEmail] = useState('')
  const disabled = !emailValidation(email)
  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    try {
      await axios.post('http://www.flybanking.com/api/email', { email })
      setSubmitted(true)
    } catch (err) {
      console.error(err)
      setSubmitted(true)
    }
    setIsLoading(false)
  }

  return (
    <FormContainer>
      {submitted ? (
        <Text>
          <LaunchText>{successText}</LaunchText>
        </Text>
      ) : (
        <Form onSubmit={onSubmit} autoComplete="off">
          <Text>
            <LaunchText>{text}</LaunchText>
          </Text>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="ex. Welove@spadetools.com"
            type="email"
            name="email"
          />
          <StyledButton disabled={disabled} isLoading={isLoading} type="submit">
            <TextWrap>Sign Up</TextWrap>
          </StyledButton>
        </Form>
      )}
    </FormContainer>
  )
}

/* Component Export*/

export default SignupForm

/* Component styling */

const Form = styled.form`
  z-index: 1;
  margin: auto;
  width: 500px;
  position: relative;
  display: grid;
  width: 80vw;
  max-width: 500px;
  margin-top: 50px;
`

const StyledButton = styled(Button)`
  position: relative;
  margin: 20px auto;
  max-width: 250px;
  min-width: 150px;

  @media screen and (min-width: 568px) {
    min-width: 200px;
    border-radius: 20px;
  }
`

const Text = styled.div`
  z-index: 1;
  margin: auto;
  width: 500px;
  position: relative;
  display: grid;
  padding: 20px 20px;
  width: 80vw;
  max-width: 500px;
  margin-top: 50px;
`

const FormContainer = styled.div`
  z-index: 1;
  height: 100vh;
  padding: 10px;
  margin: auto;
  background: rgba(255, 255, 255, 0.5);
`

const LaunchText = styled.h2`
  font-size: 20px;
  line-height: 1.5;
  color: black;
  font-weight: 100;
  text-align: center;
  margin-bottom: 50px;
  white-space: pre-wrap;

  @media screen and (min-width: 568px) {
    font-size: 30px;
  }
`

const TextWrap = styled.p`
  font-size: 12px;

  @media screen and (min-width: 568px) {
    font-size: 16px;
  }
`
